import {TypePost} from "./TypePost";

export const post_example : TypePost[] =[
    {
        id: "1",
        title: "notification1",
        content:"notification1 context",
        createdDate:"2023-09-25T09:42:29"
    },
    {
        id: "2",
        title: "notification2",
        content:"notification2 context",
        createdDate:"2023-09-25T09:42:29"
    }
]
