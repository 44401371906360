import React, {Component} from 'react';
import {css} from 'styled-components';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Table로그인사용자 from "./App/이벤트로그/Table로그인사용자";
import Table비로그인사용자 from "./App/이벤트로그/Table비로그인사용자";
import MQttExam from "./App/Experiment/MQttExam";
import Login from "./libs/auth/Login";
import _예약대시보드 from "./App/대시보드예약n진행상황/_예약대시보드";
import _예약관리 from "./App/예약관리/_예약관리";
import _예약대시보드V2 from "./App/대시보드예약n진행상황/_예약대시보드V2";
import _대기자대시보드 from "./App/대기자대시보드/_대기자대시보드";
import _물리치료실 from "./App/대기자대시보드/_물리치료실";
import _도수센터 from "./App/대기자대시보드/_도수센터";
import _근태관리 from './App/근태관리/_근태관리';
import Blockchain from "./App/블럭체인/_근태관리";
import _NFC태그관리 from "./App/NFC태그관리/NFC태그관리"
import Info from "./App/Info/Info";
import Exam3 from "./App/근태관리/Exam3";
import Noti from "./App/접수/Noti";
import Board from "./App/Board/Board";
import BoardAdmin from './App/BoardAdminV2/BoardAdmin';
import ExamLayout from "./App/Experiment/ExamLayout";
import {profile} from "./BuildProfile";
import {ConfigProvider} from "antd";
import _보안 from './App/순찰/대시보드/_보안';
import Monitor from "./App/모니터링/Monitor";
import Nfc태그관리V2 from "./AppV2/출퇴근_MTS관리/출퇴근MTS관리V2";
import _직원관리 from "./AppV2/출퇴근_직원관리/_직원관리";
import Home from "./AppV2/Home";
import ErrorTest from "./AppV2/ErrorTest";
import AuthTest from "./AppV2/experiment/AuthTest";
import 보안MTS관리V2 from "./AppV2/보안_MTS관리/MTS/보안MTS관리V2";

class App extends Component {
    render() {
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        // @ts-ignore
        return (
            <BrowserRouter>
                <Routes>
                    {/*<Route path={"/"} element={<Links/>}/>*/}
                    <Route path={"/"} element={<Home/>}/>
                    <Route path={"/login"} element={<Login/>}/>
                    <Route path={"/board"} element={<Board/>}/>
                    <Route path={"/patrol"} element={<보안MTS관리V2/>}/>
                    {/*<Route path={"/board_admin"} element={<BoardAdmin/>}/>*/}
                    <Route path={"/board_admin"} element={<BoardAdmin/>}/>
                    <Route path={"/monitor"} element={<Monitor/>}/>
                    <Route path={"/info"} element={<Info/>}/>
                    <Route path={"/exam"} element={<Exam3/>}/>
                    <Route path={"/examlayout"} element={<ExamLayout/>}/>
                    <Route path={"/reception"} element={<Noti/>}/>
                    <Route path={"/attendance"} element={<_근태관리/>}/>
                    <Route path={"/patrol"} element={<_보안/>}/>
                    <Route path={"/nfcadmin"} element={<_NFC태그관리/>}/>

                    <Route path={"/mtsadmin"} element={<_NFC태그관리/>}/>
                    <Route path={"/event_logs_employee"} element={<Table로그인사용자/>}/>
                    <Route path={"/event_logs_consumer"} element={<Table비로그인사용자/>}/>

                    <Route path={"/dashboard1"} element={<_물리치료실/>}/>
                    <Route path={"/dashboard2"} element={<_도수센터/>}/>
                    <Route path={"/blockchain"} element={<Blockchain/>}/>

                    <Route path={"/reserve"} element={<_예약관리/>}/>
                    <Route path={"/reserve_dash_board"} element={<_예약대시보드/>}/>
                    <Route path={"/reserve_dash_board2"} element={<_예약대시보드V2/>}/>
                    <Route path={"/waitlist"} element={<_대기자대시보드/>}/>
                    <Route path={"/mqtt"} element={<MQttExam/>}/>
                    <Route path={"/error"} element={<ErrorTest/>}/>
                    <Route path={"/dev"} element={
                        <div css={css`
                          background: #6d9eff;
                          //display: flex;
                          //flex-direction: column;
                          //align-items: center;
                          height: 100vh;
                        `}>
                            <div css={css`
                              //width: 100px;
                              //height: 100px;
                              background: bisque;
                              border: #282c34 solid 1px`}>
                                Hello
                            </div>

                            <div css={css`
                              //width: 100px;
                              //height: 100px;
                              background: bisque;
                              border: #282c34 solid 1px`}>
                                Hello
                            </div>
                        </div>
                    }/>
                    <Route path={"/nfcadminV2"} element={<Nfc태그관리V2/>}/>
                    <Route path={"/employee"} element={<_직원관리/>}/>
                    <Route path={"/authtest"} element={<AuthTest/>}/>
                    <Route path={"*"} element={<span>404</span>}/>
                </Routes>
            </BrowserRouter>
        );
    }
}

class Wrap extends Component {
    // state = {locale_kr};
    // state = {locale:locale_jp};
    state = {locale: null};

    componentDidMount() {
        // import(profile.import_path).then(value => {
        // import("antd/lib/locale/ja_JP").then(value => {
        /*import("antd/lib/locale/ko_KR").then(value => {
        // import("antd/lib/locale/ko_KR").then(value => {
            console.log('***', JSON.stringify(value));
            this.setState({locale:value})
        })*/
    }

    render() {
        /* if(this.state.locale == null)
             return(
                 <div>
                     로딩중...
                 </div>
             )*/
        return (
            <div>
                {/*<ConfigProvider locale={this.state.locale}>*/}
                <ConfigProvider locale={profile.locale}>
                    <App/>
                </ConfigProvider>
            </div>
        );
    }
}

export default Wrap;
