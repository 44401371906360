import React, {Component} from 'react';
import {observer} from "mobx-react";
import {WrapMessageAPI} from "../../common/HocMessage";
import "../css/commonTable.css"
import DateTimeIndicator from "../../common/DateTimeIndicator";
import {css} from "styled-components";
import styled from "styled-components";
import icon_logo from "../../asset/seail hospital_logo.png";
import {columns_user, Item} from "./Item";
import EditableTable from "../../antd_common/EditableTable";
import {Store} from './Store';

const LogoImage = styled.img`
    display: flex;
    width: 180px;
    //width: 880px;
    align-items: flex-end;
    object-fit: contain;
`;

const HeadTop = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 10px;
`

const HeadText = styled.p`
    text-align: center;
    font-size: 3em;
    font-weight: bold;;
`


/*class Store {

    data: any[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    async load() {
        return new Promise<any>((resolve, reject) => {
            this.data = data;
            resolve(data);
        });
    }
}*/

const store = new Store();

const CompoHead_old = (props: any) => {
    return (
        <div>
            <HeadTop>
                <LogoImage src={icon_logo}/>
                <HeadText>MTS관리</HeadText>
                <DateTimeIndicator/>
            </HeadTop>
        </div>)
}

const CompoHead = (props: any) => {
    return (
        <div>
            <div css={css`
                display: flex;
                justify-content: space-between`}>
                <LogoImage src={icon_logo}/>
                <HeadText>MTS관리</HeadText>
                <DateTimeIndicator/>
                {/*</HeadTop>*/}
            </div>

        </div>
    );
}

class _근태관리 extends Component<any> {

    /*  componentDidMount() {
          store.load();
      }*/

    render() {
        return (
            <div css={css`padding: 0em`}>
                <EditableTable store={store} columns_user={columns_user}/>
            </div>
        );
    }
}

// observer()
// WrapMessageAPI
export default WrapMessageAPI(observer(_근태관리));
