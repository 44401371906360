import React, {Component} from 'react';
import {Table} from "antd";
import {makeAutoObservable} from "mobx";
import {gql} from "@apollo/client";
import {observer} from "mobx-react";
import { apollo_client } from '../../common/ApolloHelpper';
import {MQTT} from "../../common/MQTT";
import {WrapMessageAPI} from "../../common/HocMessage";
import {MqttHelpper} from "../../common/MqttHelpper";

class Store {

    data = [];


    constructor() {
        makeAutoObservable(this);
    }

    async load() {

        let newVar = await apollo_client.query({
            query: gql`
                query {
                    eventLogs_employee {
                        id
                        place
                        action
                        action_date
                        createdDate
                        eventOwner {
                            
                            name
                            phone
                            sn
                        }
                    }
                }
            `
            , fetchPolicy: "no-cache"
        }).then(value => {
                let data1 = value.data.eventLogs_employee.map((item: any) => {
                    return {...item, name: item.eventOwner.name, phone: item.eventOwner.phone, sn: item.eventOwner.sn}
                });
                this.data = data1
                console.log(value.data);
            }
        ).catch(reason => {
            console.log(reason);
        });

        return newVar;
    }
}

class Table로그인사용자 extends Component<any> {
    store = new Store();


    mqttHelpper!:MqttHelpper




    componentDidMount() {
        this.store.load();
        this.mqttHelpper = new MqttHelpper((topic, message) => {
            this.store.load();
            console.log('topic', topic);
            let parse = JSON.parse(message);

            // this.props.messageApi?.success(JSON.stringify(parse));
            this.props.messageApi?.success(`${parse._성명} ${parse.TAG}`);
        });
        this.mqttHelpper.init()
    }

    componentWillUnmount() {
        // this.mqtt.close();
        this.mqttHelpper.close()
    }

    render() {
        return (
            <div>

                <Table dataSource={this.store.data} columns={[
                    {
                        title: "id",
                        dataIndex: "id",
                        key: "id",

                    },
                    {
                        title: "place",
                        dataIndex: "place",
                        key: "place",
                    },
                    {
                        title: "action",
                        dataIndex: "action",
                        key: "action",
                    },
                    {
                        title: "userID",
                        dataIndex: "userID",
                        key: "userID",
                    },
                    {
                        title: "이름",
                        dataIndex: "name",
                        key: "이름",
                    },
                    {
                        title: "전화번호",
                        dataIndex: "phone",
                        key: "phone",
                    },
                    {
                        title: "action_date",
                        dataIndex: "action_date",
                        key: "action_date",
                    }
                ]}/>
            </div>
        );
    }
}

// observer()
// WrapMessageAPI
export default WrapMessageAPI(observer(Table로그인사용자));
