import React, {Component} from 'react';
import {makeAutoObservable} from "mobx";
import {apollo_client} from "../../common/ApolloHelpper";
import {gql} from "@apollo/client";
import {observer} from "mobx-react";


class Store {
    dataSource = [];


    constructor() {
        makeAutoObservable(this);
    }

    async load() {
        let newVar = await apollo_client.query({
            query: gql`
                query {
                    reserversForDashboard {
                        name
                        reserve_date
                        enter
                        start
                        end
                    }
                }
            `,
            fetchPolicy: "no-cache"
        }).then(value => {
            /*let reserversForDashboard = value.data.reserversForDashboard.map((item:any)=>({
                ...item,
                customer:item.customer.name,
                // place:item.place.name,
            }));*/
            let reserversForDashboard = value.data.reserversForDashboard;

            this.dataSource = reserversForDashboard;
        });

        return newVar;
    }
}

const store = new Store();

const Row = (props: any) => {
    return (
        <>{JSON.stringify(props.item)}</>
    )
}

class _예약대시보드V2 extends Component {

    componentDidMount() {
        store.load();
    }

    render() {
        return (
            <div>
                {store.dataSource.map(item => <Row item={item}/>)}
            </div>
        );
    }
}

// observer()
export default observer(_예약대시보드V2);
