import React, {Component, useState} from 'react';
import {TypePost} from "./TypePost";
import {RepoPost} from "./RepoPost";
import {Drawer} from "antd";

const repo = new RepoPost();


const PostItem = (props: { item: TypePost,cb:(item:TypePost)=>void }) => {
    return (
        <div>
            <p onClick={(e:any)=>{
                console.log("onclick!")
                props.cb(props.item);
            }}>{props.item.title}</p>
            {/*<p>{props.item.content}</p>*/}
        </div>
    )
}

const PostList = (props: { list: TypePost[] }) => {
    const [open, setOpen] = useState(false);

    const [item,setItem] = useState<TypePost>()
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <>
            {props.list.map(value => <PostItem item={value} cb={(item:TypePost)=>{
                setItem(item);
                showDrawer();
            }}/>)}
            <Drawer onClose={onClose} open={open} title={item?.title}>
                <p>
                    {item?.content}
                </p>
            </Drawer>
        </>
    )
}

class Board extends Component {
    state: { posts: TypePost[] } = {posts: []}

    componentDidMount() {
        repo.fetchPost().then(value => {
            this.setState({posts: value})
            console.log(value);
        })

    }

    render() {
        return (
            <div>
                <PostList list={this.state.posts}/>
            </div>
        );
    }
}

export default Board;