import React, {Component} from 'react';
import {create} from "zustand";
import {Button, DatePicker} from "antd";
import dayjs, {Dayjs} from "dayjs";

const store = create((set) => ({
    count: 5,
    inc: () => set((state: any) => ({count: state.count + 1})),
    dec: () => set((state: any) => ({count: state.count - 1}))
    // dec: () => setState({count: getState.count - 1})

}));

interface TStoreDate{
    date: Dayjs;
    inc : () => void;
    setDate(date:Dayjs):any;
}

const storeDate = create<TStoreDate>(set => ({
    date: dayjs(),
    inc: () => {
        set((state) => ({
            date: state.date.add(1, "day")
        }));
    },
    setDate: (date) => {
        set((state) => ({date}));
    }
}));

const Comop1 = () => {
    let {inc, dec} = store((state: any) => state);
    let {date, inc: inc2, setDate} = storeDate(state => state);

    return (
        <div>
            {/*{date.format('YYYY-MM-DD')}*/}
            {JSON.stringify(date)}
            <DatePicker value={date} onChange={(day) => {
                if (day != null)
                    setDate(day)
            }}/>
            Hello!
            {store((state: any) => state.count)}
            {/*{store.getState().count}*/}
            <Button onClick={event => {
                inc2();
                // console.log(date)
            }}>+</Button>
            <Button onClick={dec}>-</Button>
        </div>
    );

}

class Info extends Component {
    render() {
        let state: any = store.getState();
        let count = state.count;
        return (
            <div>
                {count}
                {/*{store((state:any)=>state.count)}*/}
                {/*{store.getState().count}*/}
                <Button onClick={event => {
                    state.inc();
                    console.log(state.count);
                }}>+</Button>
                <Button onClick={state.dec}>-</Button>
            </div>
        );
    }
}

// export default Info;
export default Comop1;
