import React, {Component} from 'react';
import {columns_user, Item} from "./Item";
import {Store} from "./Store";
import {css} from "styled-components";
import EditableTable from "../../Common/EditableTable";


const store = new Store()
class Zz시간대관리 extends Component<any, any> {
    render() {
        return (
            <div css={css`padding: 0em;display: flex`}>
                <EditableTable store={store} columns_user={columns_user}/>
            </div>
        );
    }
}

export default Zz시간대관리;