import React, {Component} from 'react';
import {Table} from "antd";
import {makeAutoObservable} from "mobx";
import {gql} from "@apollo/client";
import {observer} from "mobx-react";
import {apollo_client} from '../../common/ApolloHelpper';
import {WrapMessageAPI} from "../../common/HocMessage";
import {MqttHelpper} from "../../common/MqttHelpper";

class Store {

    data = [];


    constructor() {
        makeAutoObservable(this);

        console.log('const2')
    }

    async load() {

        let newVar = await apollo_client.query({
            query: gql`
                query {
                    eventLogs_consumer {
                        id
                        place
                        action
                        action_date
                        createdDate
                        consumer_name
                        consumer_phone
                        consumer_scno
                        consumer_address
                    }
                }
            `
            , fetchPolicy: "no-cache"
        }).then(value => {
                // let data1 = value.data.eventLogs_consumer.map((item: any) => {
                //     return {...item, name: item.eventOwner.name, phone: item.eventOwner.phone, sn: item.eventOwner.sn}
                // });
                this.data = value.data.eventLogs_consumer
                console.log(value.data);
            }
        ).catch(reason => {
            console.log(reason);
        });

        return newVar;
    }
}

class Table비로그인사용자 extends Component<any> {
    store = new Store();
    mqttHelpper!: MqttHelpper
    componentDidMount() {
        this.store.load();

        this.mqttHelpper = new MqttHelpper((topic, message) => {
            this.store.load();
            console.log('topic', topic);
            let parse = JSON.parse(message);

            // this.props.messageApi?.success(JSON.stringify(parse));
            this.props.messageApi?.success(`${parse._성명} ${parse.TAG}`);
        });
        this.mqttHelpper.init()

    }

    componentWillUnmount() {
        this.mqttHelpper.close()
    }

    render() {
        return (
            <div>

                <Table dataSource={this.store.data} columns={[
                    {
                        title: "id",
                        dataIndex: "id",
                        key: "id",

                    },
                    {
                        title: "place",
                        dataIndex: "place",
                        key: "place",
                    },
                    {
                        title: "action",
                        dataIndex: "action",
                        key: "action",
                    },
                    /*{
                        title: "userID",
                        dataIndex: "userID",
                        key: "userID",
                    },*/
                    {
                        title: "이름",
                        dataIndex: "consumer_name",
                        key: "consumer_name",
                    },
                    {
                        title: "전화번호",
                        dataIndex: "consumer_phone",
                        key: "consumer_phone",
                    },
                    {
                        title: "주민등록번호",
                        dataIndex: "consumer_scno",
                        key: "consumer_scno",
                    },
                    {
                        title: "주소",
                        dataIndex: "consumer_address",
                        key: "consumer_address",
                    },

                    {
                        title: "action_date",
                        dataIndex: "action_date",
                        key: "action_date",
                    }
                ]}/>
            </div>
        );
    }
}

// observer()
// WrapMessageAPI
export default WrapMessageAPI(observer(Table비로그인사용자));
