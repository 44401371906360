import {MqttClient} from "precompiled-mqtt/types/lib/client";
import mqtt from "precompiled-mqtt";
import {Predef} from "./Predef";

export class MqttHelpper {
    client: MqttClient | null = null
    private cb: (topic: string, message: any) => void;
    topic:string = "#"

    constructor(cb: (topic: string, message: any) => void,topic:string = "#") {
        this.cb = cb
        this.topic = topic;
    }

    init() {
        this.client = mqtt.connect(`mqtt://${Predef.MQTT_IP}:15675/ws`, {
            username: 'user1',
            password: '0000'
        });


        let client = this.client;
        let topic = this.topic;
        client.on('connect', function () {
            // let topic1 = `mmz_hmi/${domain_id}/${cluster_id}/${conteroler_id}`;
            // let topic2 = `mmz_hmi/${domain_id}/${cluster_id}/${conteroler_id}/+`;
            console.log('connect')
            // let topic = "nfc/#"

            client.subscribe(topic, function (err: any) {
                // client.subscribe(`#`, function (err) {
                console.log('mqtt connected!');
                /*if (!err) {
                    client.publish('presence', 'Hello mqtt')
                }*/
            })

            /*client.subscribe(topic2, function (err) {
                // client.subscribe(`#`, function (err) {
                console.log('mqtt connected!');
                /!*if (!err) {
                    client.publish('presence', 'Hello mqtt')
                }*!/
            })*/

        })

        let cb = this.cb

        client.on('message', function (topic, message) {
            // let cb = this.cb;
            // message is Buffer
            console.log('message', message.toString())
            cb(topic, message);

            // that.subject.next((iout: IOUT) => iout.onMessage(topic, message));
            // that.subject.next((iout: IOUT) => iout.onMessage(topic, message));
            // client.end()

        })


    }

    close() {
        this.client?.unsubscribe("nfc/#")
        this.client?.end(true)
    }

}
