import React, {Component} from 'react';
import EditableTable from "../Common/EditableTable";
import {columns_user} from "./Item";
import {Store} from "./Store";
import {css} from "styled-components";


const store = new Store()
class 출퇴근MTS관리V2 extends Component<any, any> {
    render() {
        return (
            <div css={css`padding: 0em; display: flex;`}>
                <EditableTable store={store} columns_user={columns_user}/>
            </div>
        );
    }
}

export default 출퇴근MTS관리V2;