import {locale} from "../locale/Locale";
import React from "react";
import styled from "styled-components";
import medi_logo from "../asset/medinomi_logo_yellow.png";
import sail_logo from "../asset/seail hospital_logo.png";
import spera_logo from "../asset/spera_logo.png";
import {profile} from "../BuildProfile";

const LogoImage = styled.img`
  width: 100%;
  height: auto;
  @media (max-width:1024px){  //769px~1024px
  }
  @media (max-width:768px){   //~768px
  }
`;

export const LogoChange = () => {

    return <LogoImage src={profile.logo}/>

    /*if (locale.strings.getLanguage() === 'jp') {
        return <LogoImage src={spera_logo}/>;
    } else {
        return <LogoImage src={medi_logo}/>;
    }*/
};
