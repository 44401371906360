import React, {Component} from 'react';
import {makeAutoObservable} from "mobx";
import {gql} from "@apollo/client";
import {Switch, Table, Tabs, Row, Col, Button} from "antd";
import {observer} from "mobx-react";
import "./table.css"
import Optional from "optional-js";
import {css} from "styled-components";
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import _ from 'lodash';
import styled from "styled-components";
import DateTimeIndicator from "../../common/DateTimeIndicator";
import {WrapMessageAPI} from "../../common/HocMessage";
import {apollo_client} from "../../common/ApolloHelpper";
import {MqttHelpper} from "../../common/MqttHelpper";
import {locale} from "../../locale/Locale";
import {LogoChange} from "../../common/LogoChange";


const strings = locale.strings;
const HeadTop = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
  ;
`

const LogoBox = styled.div`
  display: flex;
  width: 180px;
  align-items: flex-end;
`;

const LogoImage = styled.img`
  display: inline-block;
  width: 100%;
  height: auto;
  align-items: center;
`;

const DateBox = styled.div`
  display: flex;
  align-items: flex-end;
`;

const DateTimeFromString = (props: { date: string }) => {
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]:/;
    let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]/;
    let digit = re.exec(props.date);

    if (!Optional.ofNullable(digit).isPresent())
        return null;

    if (digit?.length != 3) {
        return null;
    }

    if ((digit?.[1] == '00') && (digit?.[2] == '00'))
        return null;


    return (<span>{`${digit?.[1]}시  ${digit?.[2]}분`}</span>);

};


class Store {

    filter ="도수센터"
    data = [];
    data_wait = [];
    data_progress = [];

    map !: Map<string, any>;


    constructor() {
        makeAutoObservable(this);

        console.log('const2')
    }

    change_wait(id: string, check: boolean) {
        console.log("onchanged" + id + check)
        /* Optional.ofNullable(this.map.get(id)).ifPresent(value => {
         value.progress = check
         })
         this.data = _.cloneDeep(this.data)*/
    }

    async load() {

        let place = this.filter;
        let newVar = await apollo_client.query({
            query: gql`
                query waitList($place:String!){
                    waitList(place: $place) {
                        place
                        action
                        consumer_name
                        consumer_scno
                        enter
                        start
                        end
                    }
                }
            `,
            variables:{
                place
            }
            , fetchPolicy: "no-cache"
        }).then(value => {
                let map1 = new Map<string, any>();
                let data1 = value.data.waitList.map((item: any) => {
                    map1.set(item.id, item)
                    return {
                        ...item,
                        // name: item.consumer_name,
                        // phone: item.consumer_phone,
                        // sn: item.consumer_scno,
                        progress: false
                    }
                });
                // this.data = value.data.eventLogs_consumer
                this.map = map1
                this.data = data1


                this.data_wait = _.cloneDeep(data1);
                this.data_progress = _.cloneDeep(data1);
                console.log(value.data);
            }
        ).catch(reason => {
            console.log(reason);
        });

        return newVar;
    }
}

const handle_check = (id: string, checked: boolean) => {

    store.change_wait(id, checked)
}
const columns_all = [
    {
        title: strings._이름,
        dataIndex: "consumer_name",
        key: "이름",
        align: "center",
        width: "25%",
        fixed: 'left'
    },
    {
        title: strings._입장시간,
        dataIndex: "enter",
        key: "enter",
        render: (item: any) => (<DateTimeFromString date={item}/>),
        align: "center",
        width: "25%",
        fixed: 'left'
    },
    {
        title: strings._치료시작,
        dataIndex: "start",
        key: "state",
        render: (item: any) => (<DateTimeFromString date={item}/>),
        align: "center"
        /*render: (item: any) => (
            <Switch
                // checked={item.progress}
                onChange={(checked, event) => handle_check(item.id,checked)}
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
            />
        ),*/
        ,
        width: "25%",
        fixed: 'left'
    },

    {
        title: strings._치료종료,
        dataIndex: "end",
        key: "state",
        render: (item: any) => (<DateTimeFromString date={item}/>),
        align: "center"
        /* render: (item: any) => (
             <Switch
                 // checked={item.progress}
                 onChange={(checked, event) => handle_check(item.id,checked)}
                 checkedChildren={<CheckOutlined/>}
                 unCheckedChildren={<CloseOutlined/>}
             />
         ),*/
        ,
        width: "25%",
        fixed: 'left'
    },
];

const columns_wait = [
    {
        title: strings._이름,
        dataIndex: "name",
        key: "이름",
    },
    {
        title: strings._입장시간,
        dataIndex: "action_date",
        key: "action_date",
        render: (item: any) => (<DateTimeFromString date={item}/>),
        align: "center"
    },
    {
        title: strings._치료시작,
        // dataIndex: "name",
        key: "state",
        render: (item: any) => (
            <Switch
                checked={item.progress}
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
            />
        ),
    },
];

const columns_progress = [
    {
        title: strings._이름,
        dataIndex: "name",
        key: "이름",
    },
    {
        title: strings._입장시간,
        dataIndex: "action_date",
        key: "action_date",
        render: (item: any) => (<DateTimeFromString date={item}/>),
        align: "center"
    },
    {
        title: strings._치료시작,
        // dataIndex: "name",
        key: "state",
        render: (item: any) => (
            <Switch
                checked={item.progress}
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
            />
        ),
    },
];

const Table대기자 = (props: any) => {
    return <Table
        className={"table_custom2"}
        dataSource={props.dataSource}
        columns={props.columns}
        pagination={{pageSize:10, position: ["bottomCenter"]}}
    />
}
const store = new Store();

class _대기자대시보드 extends Component<any> {
    // store = new Store();
    mqttHelpper!: MqttHelpper


    componentDidMount() {

        store.filter = this.props.place
        store.load();


        this.mqttHelpper = new MqttHelpper((topic, message) => {
            store.load();
            console.log('topic', topic);
            let parse = JSON.parse(message);

            // this.props.messageApi?.success(JSON.stringify(parse));
            this.props.messageApi?.success(`${parse._성명} ${parse.TAG}`);
        },this.props.topic);
        this.mqttHelpper.init()
    }

    componentWillUnmount() {
        this.mqttHelpper.close()
    }

    render() {

        let {title} = this.props;

        let titel1 = `${title}`
        return (
            <div
                css={css`
                  margin: 0 10em;
                `}
            >
                <div
                >
                    <p
                        css={css`
                          text-align: center;
                          font-size: 3em;
                          font-weight: bold;
                        `}
                    >{titel1}
                    </p>
                </div>
                <HeadTop>
                    <LogoBox>
                    <LogoChange />
                    </LogoBox>
                    <DateBox>
                        <DateTimeIndicator />
                    </DateBox>
                </HeadTop>
                {/*<Button onClick={()=>{*/}
                {/*    strings.setLanguage('ko');*/}
                {/*    this.setState({});*/}
                {/*}}>Ko</Button>*/}
                {/*<Button onClick={()=>{*/}
                {/*    strings.setLanguage('jp');*/}
                {/*    this.setState({});*/}
                {/*}}>Jp</Button>*/}
                <Table대기자
                    columns={columns_all}
                    dataSource={store.data_wait}
                />
            </div>
        );
    }
}

// observer()
export default WrapMessageAPI(observer(_대기자대시보드));
