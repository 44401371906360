import {gql} from "@apollo/client";
import {BaseItem, ID} from "../../antd_common/EditableTable";
import {apollo_client} from "../../common/ApolloHelpper";

export class RepositoryGQL<T extends BaseItem> {
    public async load() {
        try {
            let value = await apollo_client.query({
                query: gql`
                    query nfc_tags{
                        nfc_tags{
                            nfcUid
                            place
                            action
                        }
                    }
                `,
                variables: {}
                ,
                fetchPolicy: "no-cache"
            })
            let data = value.data.nfc_tags.map((item:any) => ({...item,id:item.nfcUid}));
            console.log('nfc_tags', data);
            return data;
        } catch (e) {
            return null;
        }
    };

    public async create(payload: any) {
        let {recipe_id, ingredient_id, requirement} = payload;
        let newVar = await apollo_client.mutate({
            mutation: gql`
                mutation create_ingredient_requirement($recipe_id:ID!,$ingredient_id:ID!,$requirement:Int){
                    create_ingredient_requirement(recipe_id:$recipe_id,ingredient_id:$ingredient_id,requirement: $requirement ){
                        id
                        ingredient{
                            name
                        }
                        requirements
                    }
                }
            `,
            variables: {
                recipe_id,
                ingredient_id,
                requirement,
            }
        });

    }

    public async update(input: T) {
        console.log('inout', input);
        console.log('inout', input.id);
        let assign = Object.assign(input);
        let id = input.id;
        delete assign['id'];
        delete assign['__typename'];
        console.log('inout2', assign);

        let payload = {nfc_uid:id, place: assign.place,event:assign.action}
        // return new Promise((resolve, reject) => reject());

        let ret = await apollo_client.mutate({
            mutation: gql`
                mutation updateNfcTagUid($nfc_uid:String!,$place:String,$event:String){
                    updateNfcTagUid(nfc_uid: $nfc_uid,place: $place,event: $event){
                        nfcUid
                        place
                        action
                    }
                }
            `,
            variables: payload
            ,
            fetchPolicy: "no-cache"
        })


    }

    public async delete(id: ID) {
        await apollo_client.mutate({
            mutation: gql`
                mutation updateNfcTagUid($nfc_uid:String!){
                    deleteNfcTagUid(nfc_uid: $nfc_uid){
                        nfcUid
                        place
                        action
                    }
                }
            `,
            variables: {
                nfc_uid:id
            }
        });
    }
}
