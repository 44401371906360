import React, {Component} from 'react';
import EditableTable from "../Common/EditableTable";
import {Store} from "./Store";
import {columns_user} from "./Item";
import styled, {css} from "styled-components";


const store = new Store()

class _직원관리 extends Component {
    render() {
        return (
            <div>
                <EditableTable store={store} columns_user={columns_user}/>
            </div>
        );
    }
}

export default _직원관리;