import {ID, IStore} from "../Common/EditableTable";
import {Item} from "./Item";
import {apollo_client} from "../../common/ApolloHelpper";
import {gql} from "@apollo/client";
import {result} from "lodash";
import {makeAutoObservable} from "mobx";

export class Store implements IStore<Item>{
    dataSource: Item[] = [];


    constructor() {
        makeAutoObservable(this);
    }

    create(new_item: Item): void {
    }

    delete(id: ID): void {
    }

    async load(): Promise<any> {
        try {
            let result = await apollo_client.query({
                query: gql`
                    query NFCs{
                        NFCs{
                            uid
                            place
                            event_type
                            event_display_name
                            note
                        }
                    }
                `,
                fetchPolicy: "no-cache"
            })
            console.log('result',result.data.NFCs)
            // console.log('hello!')
            // result.data.NFCs.filter((item:any) => (item.event_type == '출근') || (item.event_type == '퇴근'))
            this.dataSource = result.data.NFCs.map((item:Item)=>{return {...item,id:item.uid}}).filter((item:any) => (item.event_type == '출근') || (item.event_type == '퇴근'));
            console.log(this.dataSource);
            return result.data;
        } catch (e) {

            return null;
        }
        // return Promise.resolve(undefined);

    }

    update(old_item: Item, new_item: Item): void {
    }


}