import React, {Component} from "react";
import {css} from "styled-components";
// import Optional from "optional-js";
import styled from "styled-components";
import icon_alarm from '../asset/alarm_black.svg';
import icon_calendar_check from '../asset/calendar-check_black.svg';
import {locale} from "../locale/Locale";

const strings = locale.strings;
const StyledRoot = styled.div`
    //width: 800px;
  display: flex;
  align-items: flex-end;
  padding-right: 15px;
  padding-bottom: 5px;
  //margin: 0 8px 8.7px 0.2px;
  //border-radius: 5px;
  //background-color: #de265e;
  //background-color: #007aa2;
  color: #000000;
  text-align: center;
`

const Calendar = styled.div`
  display: flex;
  align-items: center;
  //@media (max-width: 1024px) {
  //  //769px~1024px
  //}
  //@media (max-width: 768px) {
  //  //~768px
  //  display: none;
  //}
`;

const Clock = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.3em;
`;

const IconBox = styled.div`
  width: 10px;
  display: flex;
  align-items: center;
  margin-right: 0.3em;
`;

const StyledIcon = styled.img`
  display: inline-block;
  width: 100%;
  height: auto;
  white-space: nowrap;
`;

const Text = styled.span`
  font-size: 0.9rem;
  font-weight: bold;
`;


class DateTimeIndicator extends Component {
    state = {}
    // dateString;
    // timeString;
    constructor() {
        super();
        // this.initTimer();


    }


    initTimer=()=>{
        // if(this.stop)
        //     return;

        let interval = setInterval(() => {

            let today = new Date();

            let year = today.getFullYear();
            let month = ('0' + (today.getMonth() + 1)).slice(-2);
            let day = ('0' + today.getDate()).slice(-2);

            // let dateString = year + '년 ' + month + '월 ' + day + '일';
            let dateString = `${year}년 ${month}월 ${day}일`;

            let hours = ('0' + today.getHours()).slice(-2);
            let minutes = ('0' + today.getMinutes()).slice(-2);
            let seconds = ('0' + today.getSeconds()).slice(-2);

            // let AMPM = (hours < 12) ? '(오전) ' : '(오후) ';
            let ampmString = (hours < 12) ? strings.am : strings.pm;

            // let timeString = hours + ':' + minutes + ':' + seconds + AMPM;
            // let timeString = AMPM + hours + '시' + ':' + minutes + '분'  ;

            let formatString = strings.formatString(strings._년월일,{
                year,
                month,
                day,
            });
            dateString = formatString;

            let timeString = strings.formatString(strings.time, {
                hours,
                minutes,
            });

            // console.log(dateString);
            this.setState({dateString, ampmString, timeString})


        }, 1000);
        this.stop = () => clearInterval(interval);
    }
    componentDidMount() {
        this.initTimer();
    }

    stop

    componentWillUnmount() {

        this.stop?.();
    }

    render() {
        return (
            <StyledRoot {...this.props}>
                <Calendar>
                    <IconBox>
                        <StyledIcon src={icon_calendar_check}/>
                    </IconBox>
                    <Text>
                        {this.state.dateString}
                    </Text>
                </Calendar>
                <Clock>
                    <IconBox>
                        <StyledIcon src={icon_alarm}/>
                    </IconBox>
                    <Text>
                        {this.state.ampmString}
                    </Text>
                    <Text>
                        {this.state.timeString}
                    </Text>
                </Clock>
            </StyledRoot>
        );
    }

}

export default DateTimeIndicator;
