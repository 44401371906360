import React, {Component} from 'react';
import {ConfigProvider, Layout, Menu} from "antd";
import Sider from "antd/es/layout/Sider";
import {Header} from "antd/es/layout/layout";
import 출퇴근MTS관리V2 from "./출퇴근_MTS관리/출퇴근MTS관리V2";
import _직원관리 from "./출퇴근_직원관리/_직원관리";
import _보안직원관리 from "./보안직원관리/_보안직원관리";
import styled from "styled-components";
// import icon_logo from "../asset/seail hospital_logo.png";
import icon_logo from "../asset/메디펀 로고.png";
import DateTimeIndicator from "../common/DateTimeIndicator";
import {Zz근태관리V2} from "./근태관리V2/Zz근태관리";
import {apollo_client_auth} from "../common/ApolloHelpper";
import {gql} from "@apollo/client";
import 보안MTS관리V2 from "./보안_MTS관리/MTS/보안MTS관리V2";
import {Zz보안기록2} from "./보안기록/Zz보안기록2";
import BlockChain from "./블럭체인/BlockChain";
import {Zz디지털프로필} from "./디지털프로필/디지털프로필";
import Zz보안기록리스트타입 from "./보안_MTS관리/보안기록리스트타입/Zz보안기록리스트타입";

const LogoImage = styled.img`
  display: inline-block;
  //width: 150px;
  width: 80px;
  padding-left: 15px;
  //width: 880px;
  object-fit: contain;
  justify-content: flex-start;
`;

const MainMenu = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
`;

const TitleComponent = styled.div`
  position: absolute;
  left: 0px;
  top: 80px;
  width: 100%;
  text-align: center;
  font-size: 2.5em;
  font-weight: bold;
`;

const mapComponent = new Map([
    ["출퇴근 기록", {component: (props: any) => (<Zz근태관리V2/>), displayname: "출퇴근 기록"}],
    ["출퇴근 직원 관리", {component: (props: any) => (<_직원관리/>), displayname: "출퇴근 직원 관리"}],
    ["출퇴근 MTS 관리", {component: (props: any) => (<출퇴근MTS관리V2/>), displayname: "출퇴근 MTS 관리"}],
    ["출퇴근 기록 블록체인", {component: (props: any) => (<BlockChain/>), displayname: "출퇴근 기록 블록체인"}],

    // ["보안 기록", {component: (props: any) => (<Zz보안기록2/>), displayname: "보안 기록"}],
    // ["보안 기록 리스트", {component: (props: any) => (<Zz보안기록리스트타입/>), displayname: "보안 기록 리스트"}],
    // ["보안 직원관리", {component: (props: any) => (<_보안직원관리/>), displayname: "보안 직원 관리"}],
    // ["보안 MTS 관리", {component: (props: any) => (<보안MTS관리V2/>), displayname: "보안 MTS 관리"}],
    // ["보안 기록 블록체인", {component: (props: any) => (<BlockChain/>), displayname: "보안 기록 블록체인"}],

    // ["메디프로필 기록", {component: (props: any) => (<Zz디지털프로필/>), displayname: "메디프로필 기록"}],
    // ["메디프로필 사용자 관리", {component: (props: any) => (<Zz디지털프로필/>), displayname: "메디프로필 사용자 관리"}],
    // ["메디프로필 기록 블록체인", {component: (props: any) => (<BlockChain/>), displayname: "메디프로필 기록 블록체인"}]
]);

const items = Array.from(mapComponent.keys()).map(value => ({key: value, label: value}))
const ObtainCompo = (props: { keyname: string }) => {
    let {keyname} = props;
    let Compo = mapComponent.get(keyname)?.component
    console.log('key', keyname);
    if (Compo)
        return <Compo/>
    else
        return <div>Error{JSON.stringify(props)}</div>
}


const Top = (props:{title?:string})=>{
    let {title} = props;
    return(
        <MainMenu>
            <LogoImage src={icon_logo}/>
            <TitleComponent>{title}</TitleComponent>
            <DateTimeIndicator/>
        </MainMenu>
    )
}
class Home extends Component {
    state:{key:string}|any = {key: "출퇴근 기록"}
componentDidMount() {
        apollo_client_auth.query({query:gql`
                {
                    auth_by_token{
                        principal
                        credentials
                        authorities {
                            role

                        }
                        details
                    }
                }
            `,fetchPolicy:"no-cache"}).then(value => {
            let {auth_by_token} = value.data;
            this.setState({...this.state,domain:auth_by_token.details})


        }).catch(reason => {
            console.log(reason)
        })
}

    render() {
        return (
            <ConfigProvider
                theme={{
                    components: {
                        Layout: {
                            headerPadding: '0px',
                            headerBg: 'white',
                            headerHeight: 60,
                        },
                    },
                }}
            >
                <Layout style={{background: 'white', border: 'solid 1px #E2E2E2', margin: '3em'}}>
                    <Header style={{lineHeight: 0, marginTop: 15, marginBottom: 5}}>
                        <Top title={mapComponent.get(this.state.key)?.displayname}/>
                    </Header>
                    <Layout style={{display: 'flex', background: 'white', border: 'solid 1px #E2E2E2'}}>
                        <Sider style={{background: 'white'}}>
                            <Menu
                                defaultSelectedKeys={[mapComponent.keys().next().value]}
                                onSelect={(i: any) => {
                                    console.log('onSelect', i)
                                    type T_Key = keyof typeof mapComponent;
                                    this.setState({key: i.key})
                                }}
                                onChange={(i: any) => {
                                    console.log('onChange', i)
                                }}
                                items={items}/>
                        </Sider>
                        {/*{JSON.stringify(this.state.key)}*/}
                        {/*<div style={{background:'#0f0'}}>Hello!</div>*/}
                        {/*<h1>{this.state.domain}</h1>*/}
                        <ObtainCompo keyname={this.state.key}/>
                        {/*<div>컨텐츠</div>*/}
                        {/*<Nfc태그관리V2/>*/}
                        {/*<this.state.Compo></this.state.Compo>*/}
                        {/*{typeof TestCompo}*/}
                    </Layout>
                </Layout>
            </ConfigProvider>
        );
    }
}

export default Home;