import React, {Component} from 'react';
import {Button} from "antd";
import {css} from "styled-components";
import {apollo_client} from "../common/ApolloHelpper";
import {gql} from "@apollo/client";

class ErrorTest extends Component {
    handle_error = ()=>{
        apollo_client.query({
            query:gql`
                query q1{
                    error_auth
                }
            `,fetchPolicy:"no-cache"

        })
    }
    render() {
        return (
            <div >
                <Button size={"large"} onClick={event => {
                    apollo_client.query({
                        query:gql`
query q1{
error_auth
}
 
 

                        `,fetchPolicy:"no-cache"
                    })

                }}>401</Button>
                <Button size={"large"} onClick={event => {

                }}>403</Button>
            </div>
        );
    }
}

export default ErrorTest;