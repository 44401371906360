import React, {Component, useEffect, useRef} from 'react';
import {Button, ConfigProvider, FormInstance, Space} from "antd";
import {makeAutoObservable} from "mobx";
import {ApolloError, gql} from "@apollo/client";
import {observer} from "mobx-react";
import Optional from "optional-js";
import "./table.css"
import {css} from "styled-components";
import styled from "styled-components";
import {ProTable, ActionType} from '@ant-design/pro-components';
import dayjs from "dayjs";
import {Subject, Subscription} from "rxjs";
import {ProColumns} from "@ant-design/pro-table/es/typing";
import _ from "lodash";
import {apollo_client} from "../../common/ApolloHelpper";
import {WrapMessageAPI} from "../../common/HocMessage";
import {MQTT} from "../../common/MQTT";
import DateTimeIndicator from "../../common/DateTimeIndicator";
import {LogoChange} from "../../common/LogoChange";
import {withRouter} from "../../common/UtilCompo";
import {withRef} from "../../common/UtilCompo";
import {Excel} from "../../common/Excel";
import {locale} from "../../locale/Locale";

const strings = locale.strings;

const LogoBox = styled.div`
    width: 200px;
    height: auto;
    align-items: center;
`;

const ExelButton = styled.div`
    display: inline-block;
    align-items: flex-end;
    @media (max-width: 1024px) {
        //769px~1024px
    }
    @media (max-width: 768px) {
        //~768px
        display: none;
    }
`;

const HeadTop = styled.div`
    display: inline-block;
    margin-bottom: 10px;
    align-items: center;
    @media (max-width: 1024px) {
        //769px~1024px
        display: flex;
        justify-content: space-between;
    }
    @media (max-width: 768px) {
        //~768px
        margin-top: 2em;
    }
`
const LoginInfo = styled.div`
    display: block;
    width: 200px;
    height: auto;
    align-items: center;
    @media (max-width: 1024px) {
        //769px~1024px
        display: none;
    }
    @media (max-width: 768px) {
        //~768px
        display: none;
    }
`

const HeadText = styled.p`
    display: inline-block;
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    @media (max-width: 1024px) {
        //769px~1024px
    }
    @media (max-width: 768px) {
        //~768px
        font-size: 2em;
    }
`

const HeadBar = styled.div`
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    justify-content: space-between;
`

const ButtonGroup = styled.div`
    display: flex;

`

const ProTableWithRefHook = withRef(<ProTable/>)
const CvtDate = (props: { date: string }) => {
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]:/;
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]/;
    let re = /([0-9][0-9][0-9][0-9])-([0-9][0-9])-([0-9][0-9])T([0-9][0-9]):([0-9][0-9]):([0-9][0-9])/;
    let digit = re.exec(props.date);
    return (<span>{`${digit?.[1]}-${digit?.[2]}-${digit?.[3]} ${digit?.[4]}:${digit?.[5]}:${digit?.[6]}`}</span>);
};

const DateFromString = (props: { date: string }) => {
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]:/;
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]/;
    let re = /([0-9][0-9][0-9][0-9])-([0-9][0-9])-([0-9][0-9])T([0-9][0-9]):([0-9][0-9])/;

    let digit = re.exec(props.date);

    if (!Optional.ofNullable(digit).isPresent())
        return null;

    if (digit?.length != 6) {
        return null;
    }

    if ((digit?.[1] == '00') && (digit?.[2] == '00'))
        return null;


    //return (<span>{`${diit?.[1]}시  ${digit?.[2]}분`}</span>);
    // return (<span>{`${digit?.[1]}년  ${digit?.[2]}월 ${digit?.[3]}일 ${digit?.[4]}시  ${digit?.[5]}분`}</span>);
    return (<span>{`${digit?.[1]}년  ${digit?.[2]}월 ${digit?.[3]}일`}</span>);
};

const TimeFromString = (props: { date: string }) => {
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]:/;
    // let re = /([0-9][0-9]):([0-9][0-9]):[0-9][0-9]/;
    let re = /([0-9][0-9][0-9][0-9])-([0-9][0-9])-([0-9][0-9])T([0-9][0-9]):([0-9][0-9])/;

    let digit = re.exec(props.date);

    if (!Optional.ofNullable(digit).isPresent())
        return null;

    if (digit?.length != 6) {
        return null;
    }

    if ((digit?.[1] == '00') && (digit?.[2] == '00'))
        return null;


    //return (<span>{`${diit?.[1]}시  ${digit?.[2]}분`}</span>);
    return (<span>{`${digit?.[4]}시  ${digit?.[5]}분`}</span>);
};

class Store {

    columns = _.cloneDeep(columns_base);
    count = 0;
    inc = () => {
        this.count++;
        console.log(this.count);
    }
    data: any[] = [];
    departments: Map<string, string> = new Map();
    positions: Map<string, string> = new Map();

    reload ?: () => void

    regReloadfunc(cb: () => void) {
        this.reload = cb;
    }

    constructor() {
        makeAutoObservable(this);
    }

    cvtDatetime = (datetime: Date) => {
        if (datetime == null)
            return undefined;

        // new Dayjs()
        return dayjs(datetime).format("YYYY-MM-DDTHH:mm:ss")
    }

    loadImplV2 = async (params: any) => {
        // let dateRangeElement = params?.dateTimeRange2?.[0];

        // let format = dateRangeElement?.format("YYYY-MM-DD HH:mm:ss");
        let payload: {
            start_date?: string,
            end_date?: string

            current?: number,
            pageSize?: number,
            // start_date:LocalDateTime
            // end_date:LocalDateTime
            name?: string,
            department?: string,
            position?: string,
            team?: string,
            attendance_type?: string,
            auth_type?: string,
            terminal?: string,
        } = {
            // start_date: this.cvtDatetime(params?.dateTimeRange2?.[0]?.$d),
            // end_date: this.cvtDatetime(params?.dateTimeRange2?.[1]?.$d)
            current: params?.current,
            pageSize: params?.pageSize,
            start_date: params?.date_range?.[0]?.replace(' ', 'T'),
            end_date: params?.date_range?.[1]?.replace(' ', 'T'),
            name: params?.name,
            department: params?.department,
            position: params?.position,
            team: params?.team,
            attendance_type: params?.attendance_type,
            auth_type: params?.auth_type,
            terminal: params?.terminal
        }

        /*if (dateRangeElement != null) {
            let dateRangeElement1: Date = dateRangeElement.$d;
            let date = dayjs(dateRangeElement1);
            let s = date.format("YYYY-MM-DDTHH:mm:ss");
            console.log(s);

        }*/
        // payload.start_date = Optional.ofNullable(params.date_range?.[0]).map((value:Dayjs) => value.format( "YYYY-MM-DD HH:mm:ss")).orElse('')


        let query = gql`
            query attendences{
                attendences{
                    taggingDate
                    createdDate
                    nfc_uid
                    event_name
                    place

                    name
                    team
                    position
                    department
                }

            }
        `;

        /* let newVar = await apollo_client.query({
             query ,
             variables: {
                 input: payload
             }
             , fetchPolicy: "no-cache"
         }).then(value => {
                 this.data = value.data.attendance_condition_v2.map((item: any) => {
                     return {...item, ...item.eventOwner}
                 });
             }
         ).catch(reason => {
             console.log(reason);
             if (reason.message == 'Forbidden') {
                 console.log("403")

             }
         });*/

        return apollo_client.query({
            query,
            variables: {
                input: payload
            }
            , fetchPolicy: "no-cache"
        })

    }

    loadImplV3 = async (params: any) => {
        // let dateRangeElement = params?.dateTimeRange2?.[0];

        // let format = dateRangeElement?.format("YYYY-MM-DD HH:mm:ss");
        let payload: {
            start_date?: string,
            end_date?: string

            current?: number,
            pageSize?: number,
            // start_date:LocalDateTime
            // end_date:LocalDateTime
            name?: string,
            department?: string,
            position?: string,
            team?: string,
            attendance_type?: string,
            auth_type?: string,
            terminal?: string,
        } = {
            // start_date: this.cvtDatetime(params?.dateTimeRange2?.[0]?.$d),
            // end_date: this.cvtDatetime(params?.dateTimeRange2?.[1]?.$d)
            current: params?.current,
            pageSize: params?.pageSize,
            start_date: params?.date_range?.[0]?.replace(' ', 'T'),
            end_date: params?.date_range?.[1]?.replace(' ', 'T'),
            name: params?.name,
            department: params?.department,
            position: params?.position,
            team: params?.team,
            attendance_type: params?.attendance_type,
            auth_type: params?.auth_type,
            terminal: params?.terminal
        }




        let query = gql`
            query eventLogEmployees{
                eventLogEmployees{
                    createdDate
                    taggingDate
                    mid
                    name
                    nfc_uid
                    place
                    eventName
                }

            }
        `;


        return apollo_client.query({
            query
            , fetchPolicy: "no-cache"
        })

    }



    loadMast = async () => {

        apollo_client.query({
            query: gql`
                query {
                    departments
                    positions
                }
            `
        }).then(value => {
            let columns = _.cloneDeep(columns_base);
            this.departments = value.data.departments.reduce((p: any, c: any) => {
                (p as Map<string, object>).set(c, c);
                return p;
            }, new Map());
            (obtainColumn(columns, 'department') as any).valueEnum = this.departments;

            this.positions = value.data.positions.reduce((p: any, c: any) => {
                (p as Map<string, object>).set(c, c);
                return p;
            }, new Map());
            (obtainColumn(columns, 'position') as any).valueEnum = this.positions;

            this.columns = columns;
        }).catch(reason => {

        })
    }

    subject = new Subject<string>();

    async load(params?: any) {

        // return this.loadImplOld();
        try {
            // let value = await this.loadImplV2(params);
            let value = await this.loadImplV3(params);
            // this.data = value.data.attendance_condition_v2.map((item: any) => {
            //     return {...item, ...item.eventOwner}
            // });

            this.data = value.data.eventLogEmployees;
            console.log('attendences', this.data);
            // this.reload?.();
        } catch (e) {
            console.log(e);
            let error = e as ApolloError;
            if (error.message == 'Forbidden') {
                console.log("403")
                this.subject.next("403");
            }

            if (error.message == 'Unauthorized') {
                console.log("403")
                this.subject.next("401");
            }
        }
    }
}

const obtainColumn = (columns: any, key: string): ProColumns<any, any> | undefined => {
    return columns.find((value: any) => {
        return value.key == key;
    })
}


const columns_base: ProColumns<any, any>[] = [
    /*{
        title: '时间范围',
        key: 'dateTimeRange',
        dataIndex: 'dateTimeRange',
        hideInTable: true,
        valueType: 'dateTimeRange',
        fieldProps: {
            // placeholder: []
        },
        renderFormItem: (_, { type, defaultRender }) => {
            if (type === 'form') {
                return null;
            }

            return defaultRender(_);
        },
    },*/

    {
        title: strings._근태일시,
        // dataIndex: "action_date",
        // dataIndex: 'dateTimeRange',
        // key: "dateTimeRange2",
        key: "date_range",
        align: "center",
        hideInTable: true,
        valueType: 'dateTimeRange',
        ellipsis: true,
        /*fieldProps: {
            // placeholder: []
        },*/
        /*renderFormItem: (_, { type, defaultRender }) => {
            if (type === 'form') {
                return null;
            }

            return defaultRender(_);
        },*/

        // initialValue: [Date.now(), Date.now()],
        // render: (item: any) => (<CvtDate date={item}/>),
        /*search: {
            transform: (item: any) => ({
                startTime: item[0],
                endTime: item[1],
            }),
        }*/
    },
    {
        hideInSearch: true,
        title: strings._근태일시,
        dataIndex: "taggingDate",
        key: "action_date1",
        align: "center",
        // valueType : 'dateTimeRange',
        // initialValue: [Date.now(), Date.now()],
        render: (item: any) => (<CvtDate date={item}/>),
        /*search: {
            transform: (item: any) => ({
                startTime: item[0],
                endTime: item[1],
            }),
        }*/
    },
    /*{
        title: "Actor ID",
        dataIndex: "kakaoId",
        key: "kakaoId",
        align: "center",
        hideInSearch: true,
        ellipsis: true,
        responsive: ["sm"]
    },*/
    {
        title: strings._이름,
        dataIndex: "name",
        key: "name",
        align: "center",
    },
    {
        title: strings._부서,
        dataIndex: "department",
        key: "department",
        align: "center",
        valueType: 'select',
        /*valueEnum: props.store.departments.reduce((previousValue, currentValue) => {
            previousValue[currentValue] = {text: currentValue}
            return previousValue;
        }, {} as any),*/

    },
    {
        title: strings._팀,
        dataIndex: "team",
        key: "team",
        align: "center",
        ellipsis: true,
        responsive: ["sm"]
    },
    {
        title: strings._직책,
        dataIndex: "position",
        key: "position",
        align: "center",
        valueType: 'select',
        /*valueEnum: props.store.positions.reduce((previousValue, currentValue) => {
            previousValue[currentValue] = {text: currentValue}
            return previousValue;
        }, {} as any)*/
    },
    {
        title: strings._근태구분,
        dataIndex: "eventName",
        key: "event_name",
        align: "center",
        valueType: 'select',
        valueEnum: new Map([
            ['출근', '출근'],
            ['퇴근', '퇴근'],
        ]),
    }
    ,
    {
        title: strings._인증방법,
        dataIndex: "authentication_method",
        key: "auth_type",
        align: "center",
        responsive: ["sm"]
    },
    {
        title: strings._단말기,
        dataIndex: "place",
        key: "terminal",
        align: "center",
        responsive: ["sm"]
    },
];

const ProTableCustom = observer((props: { store: Store, update?: any }) => {
        let ref = useRef<FormInstance>();
        let aref = useRef<ActionType>();
        // let columns = _.cloneDeep(columns_base);
        // (obtainColumn(columns,'department') as any).valueEnum = props.store.departments;
        // (obtainColumn(columns,'position') as any).valueEnum = props.store.positions;

        // let [rerender, setRerender] = useState(false);
        useEffect(() => {
            props.store.regReloadfunc(() => reload());
            return () => {
                props.store.regReloadfunc(() => {
                });
            }
        });

        function reload() {
            aref.current?.reload();
        }

        // props.store.regReloadfunc(reload);

        return (
            <div>
                {/*<p>{JSON.stringify((obtainColumn('department') as any).valueEnum)}</p>*/}
                {/*<p>{JSON.stringify((obtainColumn('position') as any).valueEnum)}</p>*/}
                {/*<span>{JSON.stringify(column)}</span>*/}
                {/*{props.store.count}*/}
                {/*<Button onClick={()=>props.store.inc()}>테스트</Button>*/}
                {/*<p>{strings.getLanguage()}</p>*/}
                {/*<p>{strings._근태일시}</p>*/}
                <HeadBar>
                    <ButtonGroup>
                        <Button onClick={() => {
                            ref?.current?.setFieldValue("date_range", [dayjs().startOf('day'), dayjs()])
                        }}>{strings._오늘}</Button>
                        <Button onClick={() => {
                            ref?.current?.setFieldValue("date_range", [dayjs().add(-7, 'd',).startOf('day'), dayjs()])
                        }}>{strings._일주일}</Button>
                        <Button onClick={() => {
                            ref?.current?.setFieldValue("date_range", [dayjs().add(-1, 'month').startOf('day'), dayjs()])
                        }}>{strings._한달}</Button>
                    </ButtonGroup>
                    <ExelButton>
                        <Button onClick={() => {
                            new Excel().save(props.store.data);

                        }}>{strings._엑셀다운로드}</Button>
                    </ExelButton>
                </HeadBar>
                <ProTable
                    actionRef={aref}
                    formRef={ref}
                    className={"table_custom3"}
                    request={async (params: any) => {
                        await props.store.load(params);
                        return {
                            total: props.store.data.length,
                            data: props.store.data,
                            success: true,
                        }
                        // await this.store.load();
                        /* return Promise.resolve({
                             total: this.store.data.length,
                             data: this.store.data,
                             success: true
                         });*/
                    }}
                    pagination={{pageSize: 10, position: ["bottomCenter"]}}
                    // dataSource={props.store.data}
                    columns={props.store.columns}/></div>
        );
    }
)

class Zz근태관리 extends Component<any> {
    store = new Store();


    mqtt = new MQTT((topic, message) => {
        setTimeout(() => {
            // this.store.load();
            this.store?.reload?.();
        }, 1000);
        console.log('topic', topic);
        let parse = JSON.parse(message);

        // this.props.messageApi?.success(JSON.stringify(parse));
        this.props.messageApi?.success(`${parse._성명} ${parse.TAG}`);
        // this.props.messageApi?.success(`${parse._성명} 블럭체인 트랜젝션, 마이닝 중입니다.`);
        // this.props.messageApi?.success("Hello!");
    });

    subscription?: Subscription;

    load = async () => {

        this.store.load();

    }

    componentDidMount() {

        this.store.loadMast();
        // this.store.load();
        /*this.subscription = this.store.subject.subscribe(value => {
            console.log('error', value)

            switch (value) {
                case "401":
                case "403":
                    this.props.messageApi?.error(`${value} 로그인페이지로 이동합니다.`);
                    setTimeout(args => {
                        this.props.navigate("/login"); //로그인
                    }, 2000)
                    break;

                default:
                    break;

            }


        });*/
    }

    componentWillUnmount() {
        this.mqtt.close();
        Optional.ofNullable(this.subscription).ifPresent(value => {
            value.unsubscribe();
        })
    }

    render() {
        let that = this;
        return (

            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            headerBorderRadius:0,
                        },
                    },
                }}
            >
                <div css={css`padding: 0em`}>
                    {/*<Button onClick={()=>{*/}
                    {/*    strings.setLanguage('ko');*/}
                    {/*    this.setState({});*/}
                    {/*}}>Ko</Button>*/}
                    {/*<Button onClick={()=>{*/}
                    {/*    strings.setLanguage('jp');*/}
                    {/*    this.setState({});*/}
                    {/*}}>Jp</Button>*/}
                    {/*<p>{process.env.REACT_APP_PRODUCT_FLAVOR}</p>*/}
                    <ProTableCustom store={this.store}/>
                </div>
            </ConfigProvider>
        );
    }
}

// const Zz근태관리V2 = withRouter(WrapMessageAPI(observer(Zz근태관리)));
const Zz근태관리V2 = WrapMessageAPI(observer(Zz근태관리));
// observer()
// WrapMessageAPI
// withRouter()
// export default withRouter(WrapMessageAPI(observer(Zz근태관리)));
export {Zz근태관리V2}
