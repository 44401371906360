import React, {Component} from 'react';
import {gql} from "@apollo/client";
import {apollo_client, apollo_client_auth} from "../../common/ApolloHelpper";



const test = ()=>{
    console.log('on Click!');
    apollo_client_auth.query({
        query:gql`
            query {
                login(username: "admin",password: "1111",domain: "mts_demo"){
                    principal
                    credentials
                    authorities {
                        role
                        
                    }
                    details
                }
            }
        `,
        fetchPolicy:"no-cache"
    }).then(value => {
        console.log(JSON.stringify(value));
    }).catch(reason => {
        console.log(JSON.stringify(reason));
    })

}


const test2 = ()=>{
    console.log('on Click!');
    apollo_client.query({
        query:gql`
            query {
                hello
            }
        `,
        fetchPolicy:"no-cache"
    }).then(value => {
        console.log(JSON.stringify(value));
    }).catch(reason => {
        console.log(JSON.stringify(reason));
    })

}



const test3 = ()=>{
    console.log('on Click!');
    apollo_client_auth.query({
        query:gql`
            query {
                auth_by_token{
                    principal
                    credentials
                    authorities {
                        role

                    }
                    details
                }
            }
        `,
        fetchPolicy:"no-cache"
    }).then(value => {
        console.log(JSON.stringify(value));
    }).catch(reason => {
        console.log(JSON.stringify(reason));
    })

}




class AuthTest extends Component {
    render() {
        return (
            <div>
                Home
                <button style={{fontSize: "50px"}} onClick={event => {
                    test();
                }}>테스트 Auth</button>

                <button style={{fontSize: "50px"}} onClick={event => {
                    test2();
                }}>테스트 Application
                </button>

                <button style={{fontSize: "50px"}} onClick={event => {
                    test3();
                }}>인증정보조회 Application
                </button>

            </div>
        );
    }
}

export default AuthTest;