import {ID, IStore} from "../../Common/EditableTable";
import {Item} from "./Item";
import {apollo_client} from "../../../common/ApolloHelpper";
import {gql} from "@apollo/client";
import {result} from "lodash";
import {makeAutoObservable} from "mobx";

export class Store implements IStore<Item>{
    dataSource: Item[] = [];


    constructor() {
        makeAutoObservable(this);
    }

    create(new_item: Item): void {
    }

    delete(id: ID): void {
    }

    async load(): Promise<any> {
        try {
            let result = await apollo_client.query({
                query: gql`
                    query eventLogMtsSecurityPatrol{
                        eventLogMtsSecurityPatrol {
                            createdDate
                            department
                            eventName
                            mid
                            name
                            nfc_uid
                            place
                            position
                            taggingDate
                            team
                        }
                    }
                `,
                fetchPolicy: "no-cache"
            })
            console.log('result',result.data.eventLogMtsSecurityPatrol)
            // console.log('hello!')
            this.dataSource = result.data.eventLogMtsSecurityPatrol.map((item:Item)=>{return {...item,id:item.uid}});
            console.log(this.dataSource);
            return result.data;
        } catch (e) {

            return null;
        }
        // return Promise.resolve(undefined);

    }

    update(old_item: Item, new_item: Item): void {
    }


}