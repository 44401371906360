
export class Predef {
    static scale : number | null = null;
    // static scale = 4;

    static main_width = 1920;
    static main_height = 1080;

    static drawEquipName = false;


    // static main_width = 960;
    // static main_height = 540;

    // static main_width = 1920;
    // static main_height = 1080;

    static _아울렛_갱신주기 = 5000;
    static _응급샘플_갱신주기=  5000;
    // static _갱신주기=  1000;
    static _갱신주기=  7000;
    // static MQTT_IP = '13.125.244.79';
    // static MQTT_IP = "medinomi.haslabs.co.kr";
    // static MQTT_IP = "localhost";
    // static MQTT_IP = "medinomi.jp.haslabs.co.kr";
    // static MQTT_IP = "jp.medifun.haslabs.co.kr";
    // static MQTT_IP = "patrol.haslabs.co.kr";
    static MQTT_IP = "192.168.0.104";
    // static MQTT_IP = "mtsdemo.haslabs.co.kr";


}
