import React from "react";
import {toJS} from "mobx";
import {BaseItem} from "../../antd_common/EditableTable";
import {locale} from "../../locale/Locale";

const strings = locale.strings;

export interface Item extends BaseItem{

    // 필수
    // index?: number
    // id: string | number
    // key?: number | string;

    isSelectedItem?: () => boolean
    //사용자 정의
    name: string;
    uid: string;
    place: string;
    note:string
    event_display_name: string;
}


export const columns_user = [
   /* {
        title: "id",
        dataIndex: "id",
        editable: false,
        defaultSortOrder: 'descend',
        sorter: (a:any, b:any) => a.id - b.id
    },*/
    /*{
        title: "품명",
        dataIndex: "ingredient",
        editable: false,
        render:(item:any)=><span>{item.name}</span>,
        defaultSortOrder: 'descend',
        sorter: (ra:any, rb:any) => {
            console.log(toJS(ra), toJS(rb));
            let a = ra.ingredient.name;
            let b = rb.ingredient.name;

            if(a <  b)
                return -1;
            if(a >  b)
                return 1;
            return 0;
        }

    },*/
    {
        title: "MTS UID",
        dataIndex: "uid",
        editable: false,
        align: "center",
    },
    {
        title: "장소",
        dataIndex: "place",
        editable: true,
        align: "center",

    },
    {
        title: "이벤트",
        dataIndex: "event_display_name",
        editable: true,
        align: "center",
    },
    {
        title: "NOTE",
        dataIndex: "note",
        editable: true,
        align: "center",
    }
];
