import React from "react";
import {toJS} from "mobx";
import {BaseItem} from "../../antd_common/EditableTable";
import {locale} from "../../locale/Locale";
import {Switch} from "antd";
import Optional from "optional-js";

const strings = locale.strings;

export interface Item extends BaseItem {

    // 필수
    // index?: number
    // id: string | number
    // key?: number | string;

    isSelectedItem?: () => boolean

    //사용자 정의
    username: string;
    name: string;
    uid: string;
    place: string;
    event: string;
}

const IDComponent = (props: { id: String }) => {
    let {id} = props;
    try {
        let splited_id = id.split('-')[0];
        return (<span>{splited_id}</span>)
    } catch (e) {

    }
    return (
        <div>{id}</div>
    )
}
export const columns_user = [
    /* {
         title: "id",
         dataIndex: "id",
         editable: false,
         defaultSortOrder: 'descend',
         sorter: (a:any, b:any) => a.id - b.id
     },*/
    /*{
        title: "품명",
        dataIndex: "ingredient",
        editable: false,
        render:(item:any)=><span>{item.name}</span>,
        defaultSortOrder: 'descend',
        sorter: (ra:any, rb:any) => {
            console.log(toJS(ra), toJS(rb));
            let a = ra.ingredient.name;
            let b = rb.ingredient.name;

            if(a <  b)
                return -1;
            if(a >  b)
                return 1;
            return 0;
        }

    },*/

    {
        title: "이름",
        dataIndex: "name",
        editable: true,
        align: "center",

    },
    {
        title: "사번",
        dataIndex: "idNumber",
        editable: true,
        align: "center",

    },
    {
        title: "모바일 UUID",
        dataIndex: "username",
        editable: false,
        align: "center",
        // render:(item:String) => <div>{item.split('-')[0]}</div>
        render:(item:String) => <IDComponent id={Optional.ofNullable(item).orElse("none")}/>
    },
    {
        title: "디지털 사원증 UID",
        dataIndex: "username",
        editable: false,
        align: "center",
        // render:(item:String) => <div>{item.split('-')[0]}</div>
        render:(item:String) => <IDComponent id={Optional.ofNullable(item).orElse("none")}/>
    },
    {
        title: "행정부서",
        dataIndex: "department",
        editable: true,
        align: "center",

    },
    {
        title: "소속팀",
        dataIndex: "team",
        editable: true,
        align: "center",

    },
    {
        title: "직책",
        dataIndex: "position",
        editable: true,
        align: "center",

    },

    {
        title: "관리자인증",
        align: "center",
        render: () => {
            return (<Switch/>)
        }
    }
];
