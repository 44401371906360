import React, {Component} from 'react';
import {Form, Input} from "antd";
import {MQTT} from "../../common/MQTT";
import {Subscription} from "rxjs";
import Optional from "optional-js";

class Noti extends Component {

    mqtt = new MQTT((topic, message) => {
        setTimeout(() => {
            // this.store.load();
        }, 5000);
        console.log('topic', topic);
        let parse = JSON.parse(message);

        // this.props.messageApi?.success(JSON.stringify(parse));
        // this.props.messageApi?.success(`${parse._성명} ${parse.TAG}`);
        // this.props.messageApi?.success(`${parse._성명} 블럭체인 트랜젝션, 마이닝 중입니다.`);
        // this.props.messageApi?.success("Hello!");
    },"nfc/+/접수");

    subscription?: Subscription;


    ref: any;
    componentDidMount() {
        this.ref?.setFieldsValue({
            name:"강형채"
        })

        let that = this;

        this.mqtt.subject.subscribe(action => {
            action({
                onMessage(topic: string, message: any) {

                    let parse = JSON.parse(message);
                    console.log('topic**', topic);
                    console.log(topic, parse);
                    that.ref.setFieldsValue(parse);
                }
            })

        })
    }

    componentWillUnmount() {
        this.mqtt.close();
        Optional.ofNullable(this.subscription).ifPresent(value => {
            value.unsubscribe();
        })
    }

    render() {
        return (
            <div>
                <Form ref={ref=>this.ref = ref}>
                    <Form.Item
                        label={"성명"}
                        name={"_성명"}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={"주민번호"}
                        name={"_주민번호"}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={"연락처"}
                        name={"_전화번호"}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label={"주소"}
                        name={"_주소"}
                    >
                        <Input/>
                    </Form.Item>

                </Form>
            </div>
        );
    }
}

export default Noti;
