import {makeAutoObservable} from "mobx";
import Optional from "optional-js";
import axios from "axios";
import {gql} from "@apollo/client";
import {Auth} from "./Auth";
import {apollo_client, apollo_client_auth} from "../../common/ApolloHelpper";
import {stringify} from "@ant-design/pro-components";



interface authoritiesItem {
    authority: string;
}

interface AuthDTO {
    principal: string;
    authenticated: boolean;
    credentials: string;
    name: string;
    details: string;
    authorities: Array<authoritiesItem>;
}


class AppStore {
    auth_item_name = 'auth_token';
    auth_id = 'auth_id';
    auth_roles = 'roles';

    userName = '';

    // roles: string[] = [];

    set roles(roles: string[]) {
        localStorage.setItem('roles', JSON.stringify(roles));
    }

    get id() {
        return localStorage.getItem(this.auth_id);
    }

    get roles() {
        return Optional.ofNullable(localStorage.getItem('roles')).map(value => JSON.parse(value)).orElse([]);
    }


    async impl_login(username: string, password: string,domain:string){
        return apollo_client.query({
            query: gql`
                query login($username:String!,$password: String!,$domain: String!){
                    login(username:$username,password: $password,domain: $domain ){
                        principal
                        credentials
                        authorities {
                            role
                        }
                        details
                    }
                }
            `,
            variables:{
                username,
                password,
                domain
            }
            ,
            fetchPolicy: "no-cache",

        }).then(value => {

            console.log('login with domain', JSON,stringify(value.data.login));

            let authinfo: AuthDTO = value.data.login
            // let AUTH_TOKEN = `Baerer ${token}`;
            let AUTH_TOKEN = authinfo.credentials;
            sessionStorage.setItem(appStore.auth_item_name, AUTH_TOKEN);

            appStore.roles = authinfo.authorities.map(value => value.authority);

            return AUTH_TOKEN


        }).catch(reason => {
            console.log(reason);

        });

    }

    async login(id: string, password: string,domain:string) {

       let token = await this.impl_login(id, password, domain);
        return token;

        let result = await axios.post("/api/login", {
            "username": id,
            "password": password
        });

        console.log('result****', JSON.stringify(result));

        let {data} = await axios.post("/api/login", {
            "username": id,
            "password": password
        });

        let authinfo: AuthDTO = data
        // let AUTH_TOKEN = `Baerer ${token}`;
        let AUTH_TOKEN = authinfo.credentials;
        sessionStorage.setItem(appStore.auth_item_name, AUTH_TOKEN);
        sessionStorage.setItem(appStore.auth_id, id);
        // sessionStorage.setItem("auth_token", id);
        axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

        // let {data} = await apollo_client.query({
        //     query: gql`
        //         query {
        //             roles
        //         }
        //     `,
        //     fetchPolicy: "no-cache"
        // });

        // console.log(token.roles);

        appStore.roles = authinfo.authorities.map(value => value.authority);


        return AUTH_TOKEN;
    }


    async login2(id: string, password: string) {
        let data2 = await Auth.loginV2(id, password) as any;
        let {token, roles}: { token: string, roles: string[] } = data2.data.loginV2;
        // let AUTH_TOKEN = `Baerer ${token}`;
        // let AUTH_TOKEN = token;
        sessionStorage.setItem("auth_token", token);

        axios.defaults.headers.common['Authorization'] = token;

        return {token, roles};
    }

    logout() {
        localStorage.removeItem(this.auth_item_name)
        localStorage.removeItem(this.auth_id)
        localStorage.removeItem(this.auth_roles)
    }

    get hasAdmin(): boolean {
        let find = this.roles.find(value => value == 'ADMIN');
        console.log('find', find);
        return Optional.ofNullable(this.roles.find(value => value == 'ADMIN'))
            .map(value => true)
            .orElse(false);
    }


    hasRole(role: string): boolean {
        return Optional.ofNullable(this.roles.find(value => value == role))
            .map(value => true)
            .orElse(false);
    }


    constructor() {
        makeAutoObservable(this);

        console.log('AppStore 생성')
    }
}


export const appStore = new AppStore();
