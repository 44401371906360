import {apollo_client} from "../../common/ApolloHelpper";
import {gql} from "@apollo/client";

export class Auth {
    static login(id:string,password:string) {
        return apollo_client.query({
                query: gql`
                    query login($id:String!,$password:String!){
                        login(id: $id,password: $password)
                    }
                `,
                variables:{
                    id,password
                }
            });

    }

    static loginV2(id:string,password:string) {
        return apollo_client.query({
            query: gql`
                query loginV2($id:String!,$password:String!){
                    loginV2(id: $id,password: $password){
                        token
                        roles
                    }
                }
            `,
            variables:{
                id,password
            },fetchPolicy:"no-cache"
        });

    }

}
