import React, {Component} from 'react';
import EditableTable from "../../Common/EditableTable";
import {columns_user, Item} from "./Item";
import {Store} from "./Store";
import {css} from "styled-components";
import TimeTable from "./TimeTable";
import Zz순찰장소 from "../순찰장소관리/zz순찰장소";
import Zz시간대관리 from "../시간대관리/zz시간대관리";


const store = new Store()
class 보안MTS관리V2 extends Component<any, any> {
    render() {
        return (
            <div css={css`padding: 0em;display: flex`}>
                <EditableTable store={store} columns_user={columns_user}/>
                {/*<TimeTable/>*/}
                <Zz순찰장소/>
                <Zz시간대관리/>
            </div>
        );
    }
}

export default 보안MTS관리V2;